import React, { Suspense, useContext, useEffect } from "react";

import Header from "./sidebar";
import Footer from "./footer";

import { Routes, Route, useLocation } from "react-router-dom";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../components/loader/loader";



const Home = React.lazy(() => import("../pages/home"));
const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminCreate = React.lazy(() => import("../pages/admin/add"));

const CountryCreate = React.lazy(() => import("../pages/country/add"));
const CountryList = React.lazy(() => import("../pages/country/list"));

const CityCreate = React.lazy(() => import("../pages/city/add"));
const CityList = React.lazy(() => import("../pages/city/list"));
const Addon_Pricing = React.lazy(() =>
  import("../pages/addOns/pricing/pricingMain")
);

const ChangesPassword = React.lazy(() =>
  import("../pages/settings/changePassword")
);
const ChangesPasswordAdmin = React.lazy(() =>
  import("../pages/admin/adminChangePassword")
);

const PanditPreferenceCreate = React.lazy(() =>
  import("../pages/pandit-preference/add")
);
const PanditPreferenceList = React.lazy(() =>
  import("../pages/pandit-preference/list")
);

//addons
const AddOns_PanditExtra_Create = React.lazy(() =>
  import("../pages/addOns/panditExtra/add")
);
const AddOns_PanditExtra_List = React.lazy(() =>
  import("../pages/addOns/panditExtra/list")
);
const AddOns_Pandit_Create = React.lazy(() =>
  import("../pages/addOns/pandit/add")
);
const AddOns_Pandit_List = React.lazy(() =>
  import("../pages/addOns/pandit/list")
);
const AddOns_Addon_Create = React.lazy(() =>
  import("../pages/addOns/ADDONS/add")
);
const AddOns_Addon_List = React.lazy(() =>
  import("../pages/addOns/ADDONS/list")
);

const Community_AddOns_Addon_Create = React.lazy(() =>
  import("../pages/addOns/communityAddons/add")
);
const Community_AddOns_Addon_List = React.lazy(() =>
  import("../pages/addOns/communityAddons/list")
);
const Community_Inclusions_Addon_Create = React.lazy(() =>
  import("../pages/addOns/communityInclusion/add")
);
const Community_Inclusions_Addon_List = React.lazy(() =>
  import("../pages/addOns/communityInclusion/list")
);
const AddOns_Inclusion_Create = React.lazy(() =>
  import("../pages/addOns/inclusion/add")
);
const AddOns_Inclusion_List = React.lazy(() =>
  import("../pages/addOns/inclusion/list")
);
//addons

const VendorList = React.lazy(() => import("../pages/vendor/list"));
const VendorCreate = React.lazy(() => import("../pages/vendor/add"));

const CustomerList = React.lazy(() => import("../pages/customer/list"));
const CustomerCreate = React.lazy(() => import("../pages/customer/add"));

const ServiceProviderList = React.lazy(() =>
  import("../pages/serviceProvider/list")
);
const ServiceProviderCreate = React.lazy(() =>
  import("../pages/serviceProvider/add")
);

const OrderList = React.lazy(() => import("../pages/orders/list"));
const CancelledOrderList = React.lazy(() =>
  import("../pages/orders/cancelledOrders")
);
const DraftOrderList = React.lazy(() =>
  import("../pages/orders/draftOrders")
);
const OrderCreate = React.lazy(() => import("../pages/orders/productList"));
const ProductDetails = React.lazy(() => import("../pages/orders/details"));
const SPList = React.lazy(() =>
  import("../pages/orders/Assign_Service_Provider/list")
);
const SP_Assign = React.lazy(() =>
  import("../pages/orders/Assign_Service_Provider/add")
);

const VendorAssignList = React.lazy(() =>
  import("../pages/orders/Assign_Vendor/list")
);
const Vendor_Assign = React.lazy(() =>
  import("../pages/orders/Assign_Vendor/add")
);

const PujaServiceCreate = React.lazy(() => import("../pages/pujaService/add"));
const PujaServiceList = React.lazy(() => import("../pages/pujaService/list"));
const CommunityPujaServiceCreate = React.lazy(() => import("../pages/pujaServiceCommunity/add"));
const CommunityPujaServiceList = React.lazy(() => import("../pages/pujaServiceCommunity/list"));

const MuhurthCreate = React.lazy(() => import("../pages/muhurth/add"));
const MuhurthList = React.lazy(() => import("../pages/muhurth/list"));

const PujaPackageEdit = React.lazy(() => import("../pages/pujaPackage/edit"));
const PujaPackageCreate = React.lazy(() => import("../pages/pujaPackage/add"));
const PujaPackageList = React.lazy(() => import("../pages/pujaPackage/list"));

// const StructureList = React.lazy(() => import("../pages/structure/list"));
// const StructureCreate = React.lazy(() => import("../pages/structure/add"));
const Advantage_Page = React.lazy(() =>
  import("../services/pageStructure/advantage")
);
const Home_Page = React.lazy(() => import("../services/pageStructure/home"));
const ContactDetails_Page = React.lazy(() =>
  import("../services/pageStructure/contactDetails")
);

const TestimonialList = React.lazy(() => import("../pages/testimonial/list"));
const TestimonialCreate = React.lazy(() => import("../pages/testimonial/add"));

const ExperienceList = React.lazy(() => import("../pages/experience/list"));
const ExperienceCreate = React.lazy(() => import("../pages/experience/add"));

const UpcomingFestivalList = React.lazy(() =>
  import("../pages/upcomingFestival/list")
);
const UpcomingFestivalCreate = React.lazy(() =>
  import("../pages/upcomingFestival/add")
);

const PromocodeList = React.lazy(() => import("../pages/promocode/list"));
const PromocodeCreate = React.lazy(() => import("../pages/promocode/add"));

const PromotionList = React.lazy(() => import("../pages/promotion/list"));
const PromotionCreate = React.lazy(() => import("../pages/promotion/addView"));

const LaunchPopupList = React.lazy(() => import("../pages/launchPopup/list"));
const LaunchPopupCreate = React.lazy(() => import("../pages/launchPopup/addView"));

const Privacy = React.lazy(() => import("../pages/policies/privacy"));
const Terms = React.lazy(() => import("../pages/policies/terms"));
const Cancellation = React.lazy(() => import("../pages/policies/cancellation"));

const StoryAdd = React.lazy(() => import("../pages/story/add"));
const StoryList = React.lazy(() => import("../pages/story/list"));

const HowItWorkAdd = React.lazy(() => import("../pages/howItWork/add"));
const HowItWorkList = React.lazy(() => import("../pages/howItWork/list"));
const FaqAdd = React.lazy(() => import("../pages/faq/add"));
const FaqList = React.lazy(() => import("../pages/faq/list"));

const CategoryAdd = React.lazy(() => import("../pages/blogCategory/add"));
const CategoryList = React.lazy(() => import("../pages/blogCategory/list"));

const BlogAdd = React.lazy(() => import("../pages/blog/add"));
const BlogList = React.lazy(() => import("../pages/blog/list"));

const LanguageAdd = React.lazy(() => import("../pages/language/add"));
const LanguageList = React.lazy(() => import("../pages/language/list"));

const PujaCategoryAdd = React.lazy(() => import("../pages/pujaCategory/add"));
const PujaCategoryList = React.lazy(() => import("../pages/pujaCategory/list"));

const ContactUsAdd = React.lazy(() => import("../pages/contactUs/add"));
const ContactUsList = React.lazy(() => import("../pages/contactUs/list"));

const NewsLetterAdd = React.lazy(() => import("../pages/newsLetter/add"));
const NewsLetterList = React.lazy(() => import("../pages/newsLetter/list"));

const UserCallBackAdd = React.lazy(() => import("../pages/userCallBack/add"));
const UserCallBackList = React.lazy(() => import("../pages/userCallBack/list"));

const CustomPujaEnquiryList = React.lazy(() => import("../pages/customPujaEnquiry/list"));

const Notification = React.lazy(() => import("../pages/notification/view"));

// const HelpDeskReply = React.lazy(() => import("../pages/helpDesk/reply"));
const HelpDeskReply = React.lazy(() => import("../pages/helpDesk/replyHelp"));
const HelpDeskList = React.lazy(() => import("../pages/helpDesk/list"));
const RaiseTicket = React.lazy(() => import("../pages/helpDesk/raiseTicket"));

const Setting = React.lazy(() => import("../pages/settings/setting"));
const EventDetails = React.lazy(() => import("../pages/eventDetails/addView"));

const GetAddress = React.lazy(() => import("../components/getAddress"));

//Transaction start
const SPDepositApprovalList = React.lazy(() =>
  import("../pages/spDepositApproval/list")
);
const SPDepositApprovalAdd = React.lazy(() =>
  import("../pages/spDepositApproval/add")
);
const TransactionsHistoryList = React.lazy(() =>
  import("../pages/transactionHistory/list")
);
const AddWithdrawMoney = React.lazy(() =>
  import("../pages/transactionHistory/addWithdraw")
);
const PaymentList = React.lazy(() => import("../pages/payment/list"));
//Transaction end

const AdminEarningsList = React.lazy(() =>
  import("../pages/adminEarnings/list")
);

const SPEarningsList = React.lazy(() =>
  import("../pages/spEarnings/list")
);

const VendorEarningsList = React.lazy(() =>
  import("../pages/vendorEarnings/list")
);

//Reviews&Ratings start
const RatingList = React.lazy(() => import("../pages/ratings/list"));
//Reviews&Ratings end
const EventPackagePrice = React.lazy(() =>
  import("../pages/pujaPackage/eventPackagePrice")
);

function Layout() {
  const context = useContext(UserContext);
  // const { userInfo } = useContext(UserContext);
  let location = useLocation();

  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/admins/token/refresh`,
    })
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
      })
      .catch((error) => {
        reactLocalStorage.remove("token");
        context.setAuth(false);
      });
  }
  useEffect(() => {
    refreshtoken();
  }, [location.pathname]);

  // useEffect(() => {
  //   let token = reactLocalStorage.get("token");
  //   const intrVal = setInterval(() => {
  //     refreshtoken();
  //   }, 10000);
  //   if (!token) {
  //     clearInterval(intrVal);
  //   }
  // }, []);

  return (
    <div
      className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
    >
      <ToastContainer />
      <Header />
      <div className="all-content-wrapper">
        <TopNavbar />
        <Loader />
        <Suspense fallback={"loading"}>
          {/* MODULE_MANAGER */}

          {/* {userInfo.role == "MODULE_MANAGER" ? null : userInfo.role ==
            "ACCOUNT_MANAGER" ? ( //Account_Manager
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
            </Routes>
          ) : userInfo.role === "CONTENT_MANAGER" ? null : ( //Content_Manager */}
          <Routes>
            {/* Admin */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/change-password" element={<ChangesPassword />} />
            {context?.userInfo && context?.userInfo?.role !== 'BLOG-MANAGER' && context?.userInfo?.role !== "SUB-ADMIN-OPS" && <>
              <Route path="/admin/:page" element={<AdminList />} />
              <Route path="/create-admin" element={<AdminCreate />} />
              <Route path="/edit-admin/:id" element={<AdminCreate />} />
              <Route
                path="/change-password/:id"
                element={<ChangesPasswordAdmin />}
              />
              {/* country start */}
              <Route path="/add-country" element={<CountryCreate />} />
              <Route path="/edit-country/:id" element={<CountryCreate />} />
              <Route path="/country/:page" element={<CountryList />} />
              {/* country end */}
              {/* city start */}
              <Route path="/add-city" element={<CityCreate />} />
              <Route path="/edit-city/:id" element={<CityCreate />} />
              <Route path="/city/:page" element={<CityList />} />
              {/* city end */}
              {/* pandit-preference start */}
              <Route
                path="/add-pandit-preference"
                element={<PanditPreferenceCreate />}
              />
              <Route
                path="/edit-pandit-preference/:id"
                element={<PanditPreferenceCreate />}
              />
              <Route
                path="/pandit-preference/:page"
                element={<PanditPreferenceList />}
              />
              {/* pandit-preference end */}
              {/* AddOnsCreate start */}
              {/* pandit Extra */}
              <Route
                path="/add-AddOn-1"
                element={<AddOns_PanditExtra_Create />}
              />
              <Route
                path="/edit-AddOn-1/:id"
                element={<AddOns_PanditExtra_Create />}
              />
              <Route
                path="/AddOns-1/:page"
                element={<AddOns_PanditExtra_List />}
              />
              {/* pandit Extra */}
              {/* pandit Addon */}
              <Route path="/add-AddOn-2" element={<AddOns_Pandit_Create />} />
              <Route
                path="/edit-AddOn-2/:id"
                element={<AddOns_Pandit_Create />}
              />
              <Route path="/AddOns-2/:page" element={<AddOns_Pandit_List />} />
              {/* pandit Addon */}
              {/* ADDON Addon */}
              <Route path="/add-AddOn-3" element={<AddOns_Addon_Create />} />
              <Route path="/edit-AddOn-3/:id" element={<AddOns_Addon_Create />} />
              <Route path="/AddOns-3/:page" element={<AddOns_Addon_List />} />
              {/* ADDON Addon */}
              {/* Community ADDON Addon */}
              <Route path="/add-AddOn-5" element={<Community_AddOns_Addon_Create />} />
              <Route path="/edit-AddOn-5/:id" element={<Community_AddOns_Addon_Create />} />
              <Route path="/AddOns-5/:page" element={<Community_AddOns_Addon_List />} />
              {/* Community ADDON Addon */}
              {/* Community Inclusion Addon */}
              <Route path="/add-AddOn-6" element={<Community_Inclusions_Addon_Create />} />
              <Route path="/edit-AddOn-6/:id" element={<Community_Inclusions_Addon_Create />} />
              <Route path="/AddOns-6/:page" element={<Community_Inclusions_Addon_List />} />
              {/* Community Inclusion Addon */}
              {/* INCLUSION Addon */}
              <Route path="/add-AddOn-4" element={<AddOns_Inclusion_Create />} />
              <Route
                path="/edit-AddOn-4/:id"
                element={<AddOns_Inclusion_Create />}
              />
              <Route path="/AddOns-4/:page" element={<AddOns_Inclusion_List />} />
              {/* INCLUSION Addon */}
              <Route path="/pricing-addOn/:id" element={<Addon_Pricing />} />
              {/* AddOnsCreate end */}


              {/* pujaService start */}
              <Route path="/add-puja" element={<PujaServiceCreate />} />
              <Route path="/edit-puja/:id" element={<PujaServiceCreate />} />
              <Route path="/puja/:page" element={<PujaServiceList />} />
              <Route path="/add-community-puja" element={<CommunityPujaServiceCreate />} />
              <Route path="/edit-community-puja/:id" element={<CommunityPujaServiceCreate />} />
              <Route path="/community-puja/:page" element={<CommunityPujaServiceList />} />
              <Route path="/event-details/:id" element={<EventDetails />} />
              {/* pujaService end */}
              {/* pujaPackage start */}
              <Route path="/add-puja-package" element={<PujaPackageCreate />} />
              <Route
                path="/edit-puja-package/:id"
                // element={<PujaPackageEdit />}
                element={<PujaPackageCreate />}
              />
              <Route path="/puja-package/:page" element={<PujaPackageList />} />
              <Route
                path="/puja-package/price/:serviceId/:packageId"
                element={<EventPackagePrice />}
              />
              {/* pujaPackage end */}

              {/* Structure start */}
              {/* <Route path="/add-Structure/:page" element={<StructureCreate />} />
            <Route path="/structure/:page" element={<StructureList />} /> */}
              <Route path="/Structure/advantage" element={<Advantage_Page />} />
              <Route path="/Structure/home" element={<Home_Page />} />
              <Route
                path="/Structure/contactDetails"
                element={<ContactDetails_Page />}
              />

              {/* Structure end */}

              {/* Testimonial start */}
              <Route path="/add-testimonial" element={<TestimonialCreate />} />
              <Route
                path="/edit-testimonial/:id"
                element={<TestimonialCreate />}
              />
              <Route path="/testimonial/:page" element={<TestimonialList />} />
              {/* Testimonial end */}
              {/* Promotions start */}
              <Route path="/add-promotion" element={<PromotionCreate />} />
              <Route path="/edit-promotion/:id" element={<PromotionCreate />} />
              <Route path="/promotion/:page" element={<PromotionList />} />
              {/* Promotions end */}

              {/* Promotions start */}
              <Route path="/add-launch-popup" element={<LaunchPopupCreate />} />
              <Route path="/edit-launch-popup/:id" element={<LaunchPopupCreate />} />
              <Route path="/launch-popup/:page" element={<LaunchPopupList />} />
              {/* Promotions end */}

              {/* Policies start */}
              <Route path="/add-privacy" element={<Privacy />} />
              <Route path="/add-terms" element={<Terms />} />
              <Route path="/add-cancellation" element={<Cancellation />} />
              <Route path="/add-story" element={<StoryAdd />} />
              <Route path="/edit-story/:id" element={<StoryAdd />} />
              <Route path="/story/:page" element={<StoryList />} />
              {/* Policies end */}



              {/* Promocode start */}
              <Route path="/add-promocode" element={<PromocodeCreate />} />
              <Route path="/edit-promocode/:id" element={<PromocodeCreate />} />
              <Route path="/promocode/:page" element={<PromocodeList />} />
              {/* Promocode end */}

              {/* Experience start */}
              <Route path="/add-experience" element={<ExperienceCreate />} />
              <Route path="/edit-experience/:id" element={<ExperienceCreate />} />
              <Route path="/experience/:page" element={<ExperienceList />} />
              {/* Experience end */}

              {/* Category end */}
              {/* How It Works start */}
              <Route path="/add-howitwork" element={<HowItWorkAdd />} />
              <Route path="/edit-howitwork/:id" element={<HowItWorkAdd />} />
              <Route path="/howitwork/:page" element={<HowItWorkList />} />
              <Route path="/add-faq" element={<FaqAdd />} />
              <Route path="/edit-faq/:id" element={<FaqAdd />} />
              <Route path="/faq/:page" element={<FaqList />} />
              {/* How It Works end */}

              {/* Notification start */}
              <Route path="/notification" element={<Notification />} />
              {/* Notification end */}
            </>}


            {
              context?.userInfo?.role && context?.userInfo?.role !== 'SUB-ADMIN-OPS' && <>
                {/* Blog start */}
                <Route path="/add-blog" element={<BlogAdd />} />
                <Route path="/edit-blog/:id" element={<BlogAdd />} />
                <Route path="/blog/:page" element={<BlogList />} />
                {/* Blog end */}

                {/* Category start */}
                <Route path="/add-category" element={<CategoryAdd />} />
                <Route path="/edit-category/:id" element={<CategoryAdd />} />
                <Route path="/category/:page" element={<CategoryList />} />
                {/* Category end */}
              </>
            }
            {context?.userInfo?.role && context?.userInfo?.role !== 'BLOG-MANAGER' && context?.userInfo?.role !== 'SUB-ADMIN-OPS' && <>

              {/* Muhurth start */}
              <Route path="/add-muhurth" element={<MuhurthCreate />} />
              <Route path="/edit-muhurth/:id" element={<MuhurthCreate />} />
              <Route path="/muhurth/:page" element={<MuhurthList />} />
              {/* Muhurth end */}


              {/* Language start */}
              <Route path="/add-language" element={<LanguageAdd />} />
              <Route path="/edit-language/:id" element={<LanguageAdd />} />
              <Route path="/language/:page" element={<LanguageList />} />
              {/* Language end */}
              {/* PujaCategory start */}
              <Route path="/add-puja-category" element={<PujaCategoryAdd />} />
              <Route
                path="/edit-puja-category/:id"
                element={<PujaCategoryAdd />}
              />
              <Route path="/puja-category/:page" element={<PujaCategoryList />} />
              {/* PujaCategory end */}
              {/* Transaction start */}
              <Route
                path="/add-paymentApproval"
                element={<SPDepositApprovalAdd />}
              />
              <Route
                path="/edit-paymentApproval/:id"
                element={<SPDepositApprovalAdd />}
              />
              <Route
                path="/paymentApproval/:page"
                element={<SPDepositApprovalList />}
              />
              <Route
                path="/transactions/:page"
                element={<TransactionsHistoryList />}
              />

              <Route
                path="/adminEarnings/:page"
                element={<AdminEarningsList />}
              />
              <Route
                path="/spEarnings/:page"
                element={<SPEarningsList />}
              />
              <Route
                path="/vendorEarnings/:page"
                element={<VendorEarningsList />}
              />

              <Route path="/addWithdraw" element={<AddWithdrawMoney />} />
              <Route path="/payment/:page" element={<PaymentList />} />
              {/* Transaction end */}

              <Route path="/address/:id" element={<GetAddress />} />

              <Route path="/setting" element={<Setting />} />
            </>}

            {context?.userInfo?.role && context?.userInfo?.role !== 'BLOG-MANAGER' && <>
              {/* vendor / customer/ service-provider start */}
              <Route path="/vendor/:page" element={<VendorList />} />
              <Route path="/add-vendor" element={<VendorCreate />} />
              <Route path="/edit-vendor/:id" element={<VendorCreate />} />

              <Route path="/customer/:page" element={<CustomerList />} />
              <Route path="/add-customer" element={<CustomerCreate />} />
              <Route path="/edit-customer/:id" element={<CustomerCreate />} />
              <Route
                path="/serviceProvider/:page"
                element={<ServiceProviderList />}
              />
              <Route
                path="/add-serviceProvider"
                element={<ServiceProviderCreate />}
              />
              <Route
                path="/view-serviceProvider/:id"
                element={<ServiceProviderCreate />}
              />

              {/* vendor / customer/ service-provider end */}
              {/* Orders start */}
              <Route path="/orders/:page" element={<OrderList />} />
              <Route
                path="/cancelledOrders/:page"
                element={<CancelledOrderList />}
              />
               <Route
                path="/draftOrders/:page"
                element={<DraftOrderList />}
              />
              <Route path="/add-orders" element={<OrderCreate />} />
              <Route path="/view-orders/:id" element={<OrderCreate />} />
              <Route
                path="/view-orders-details/:id"
                element={<ProductDetails />}
              />
              <Route path="/sp-list/:page" element={<SPList />} />
              <Route path="/sp-assign/:id" element={<SP_Assign />} />

              <Route path="/vendor-list/:page" element={<VendorAssignList />} />
              <Route path="/vendor-assign/:id" element={<Vendor_Assign />} />
              {/* Orders end */}
              {/* Reviews&RAtings start */}
              <Route path="/rating/:page" element={<RatingList />} />
              {/* Reviews&RAtings end */}
              {/* ContactUs start */}
              <Route path="/add-contactUs" element={<ContactUsAdd />} />
              <Route path="/edit-contactUs/:id" element={<ContactUsAdd />} />
              <Route path="/contactUs/:page" element={<ContactUsList />} />
              {/* ContactUs end */}
              {/* newsLetter start */}
              <Route path="/edit-newsLetter/:id" element={<NewsLetterAdd />} />
              <Route path="/newsLetter/:page" element={<NewsLetterList />} />
              {/* newsLetter end */}
              {/* User Call Back start */}
              <Route path="/add-usercallback" element={<UserCallBackAdd />} />
              <Route
                path="/edit-usercallback/:id"
                element={<UserCallBackAdd />}
              />
              <Route path="/usercallback/:page" element={<UserCallBackList />} />
              {/* User Call Back end */}
              {/* Custom Puja Enquiry start */}
              <Route path="/custom-puja-enquiry/:page" element={<CustomPujaEnquiryList />} />
              {/* Custom Event Request end */}
              {/* Help Desk Back start */}
              {/* <Route path="/reply-helpDesk/:id" element={<HelpDeskReply />} /> */}
              <Route path="/reply-helpDesk/:id" element={<HelpDeskReply />} />
              <Route path="/helpDesk/:page" element={<HelpDeskList />} />
              <Route path="/raise-ticket" element={<RaiseTicket />} />
              {/* Help Desk Back end */}
              {/* UpcomingFestival start */}
              <Route
                path="/add-upcomingFestival"
                element={<UpcomingFestivalCreate />}
              />
              <Route
                path="/edit-upcomingFestival/:id"
                element={<UpcomingFestivalCreate />}
              />
              <Route
                path="/upcomingFestival/:page"
                element={<UpcomingFestivalList />}
              />
              {/* UpcomingFestival end */}
            </>}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
