import { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../../context/theme";

const DropDownMenu = (props) => {
  const { children } = props;
  return (
    <>
      <div className="sidebarMenu">
        <ul className="metismenu">{children}</ul>
      </div>
    </>
  );
};

const Dropdown = (props) => {
  const { pathname } = useLocation();
  const { children, title, icon } = props;
  let matchPath = pathname || "";
  let iconMenu = icon || "icon-user";
  let target = title.replace(/ /g, "_") || "";
  const pathFilter = children.filter((d) => d.props.path == matchPath);
  return (
    <>
      <li>
        <Link
          className={`btn-toggle align-items-center ${
            pathFilter.length > 0 ? "" : "collapsed"
          }`}
          to="#"
          data-bs-toggle="collapse"
          title="Tables"
          data-bs-target={`#${target}`}
          aria-expanded="false"
        >
          <span className={`${iconMenu}`}></span>
          <span className="menu_txt">{title}</span>
        </Link>
        <ul
          className={`collapse has_menu ${pathFilter.length > 0 ? "show" : ""}`}
          id={target}
        >
          {children}
        </ul>
      </li>
    </>
  );
};

const Menu = (props) => {
  const { pathname } = useLocation();
  const { children, path, icon, title } = props;
  let matchPath = path || "/404";
  // let iconMenu = icon || 'icon-chevron-right';
  let iconMenu = icon;

  const { setBreadcrumbs } = useContext(UserContext);
  useEffect(() => {
    let pageInfo = {
      link: path,
      title: title,
    };
    if (pathname == matchPath) {
      setBreadcrumbs(pageInfo);
    }
  }, [pathname]);
  return (
    <>
      <li>
        <Link
          to={matchPath}
          className={`collapse has_menu ${
            pathname == matchPath ? "active" : ""
          }`}
        >
          <span className={`${iconMenu}`}></span>
          <span className="menu_txt">{title}</span>
        </Link>
      </li>
    </>
  );
};

export { Dropdown, Menu };
export default DropDownMenu;
