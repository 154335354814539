import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from "yup";
import apiFunc from "./api";
import millify from "millify";
import { format } from "date-fns";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import { blobToURL, urlToBlob, fromBlob, fromURL } from "image-resize-compress"; // ES6
import moment from "moment";

const common = {
  milifying: (milifyData) => {
    return millify(milifyData, {
      units: ["", "K", "M", "B", "T", "P", "E"],
      space: true,
    });
  },
  loader: (type) => {
    if (type) {
      document.body.className = "loading_page";
    } else {
      document.body.className = document.body.className.replace(
        "loading_page",
        ""
      );
    }
  },
  imgCompress: async (imageFile, mydimension) => {
    const handleBlob = async (blobFile) => {
      let xtns = imageFile.type.split("/").pop(); //png, jpeg, bmp etc....

      // quality value for webp and jpeg formats.
      const quality = 100;
      // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
      const width = 0;
      // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
      const height = 0;
      // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
      const format = xtns || "png";
      const name = blobFile.name;

      // note only the blobFile argument is required
      return fromBlob(
        blobFile,
        quality,
        mydimension.width || width,
        mydimension.height || height,
        format,
        name
      ).then((blob) => {
        // will output the converted blob file
        blob.name = name;
        return blob;
        // will generate a url to the converted file
        // blobToURL(blob).then((url) => console.log(url));
      });
    };
    let output = await handleBlob(imageFile);
    return output;
  },
  version: () => {
    let date = new Date();
    let day = date.getDate() <= 15 ? "ER" : "LT";
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthName = month[date.getMonth()].toUpperCase();
    let getFullYear = date.getFullYear().toString().substr(2);

    let version = day + "-" + monthName + "-" + getFullYear;
    return version;
  },
  base64Mime: (encoded) => {
    var result = null;
    if (typeof encoded !== "string") {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  },
  base64ReadFile: (base64String) => {
    var nonBlob = base64String == undefined ? 0 : base64String.length;
    var filetype = common.base64Mime(base64String);
    var datass = { size: nonBlob / 1000, type: filetype };
    return datass;
  },
  audioBase64: (blob) => {
    var reader = new window.FileReader();
    var base64;
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      base64 = reader.result;
      base64 = base64.split(",")[1];
    };
    return base64;
  },
  previewURL: (file) => {
    let URL = "";
    if (file != "") {
      URL = window.URL.createObjectURL(file);
    }
    return URL;
  },
  mineTypeValidate: (value) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileType = value.type;
    return (
      value &&
      (fileType === "image/jpeg" ||
        fileType === "image/bmp" ||
        fileType === "image/png") /* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
    );
  },
  fileSizeValidate: (value, size) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileSize = value.size;
    if (!fileSize) {
      fileSize = 2;
    }
    let mb = fileSize * 1024;
    return fileSize <= mb;
  },
  getMiles: (i) => {
    return (i * 0.000621371192).toFixed(1);
  },
  coupanTypeDiscount: (obj) => {
    let ctype = obj.couponType || 0;
    let price = obj.price || 0;
    let discount = obj.discount || 0;
    let minAmount = obj.minAmount || 0;
    let disUpto = obj.disUpto || 0;
    let disRate = 0;
    if (ctype == "FLAT PERCENT") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
    } else if (ctype == "FLAT PERCENT UPTO") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
    } else if (ctype == "CASH DISCOUNT UPTO") {
      disRate = price >= minAmount ? discount : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
    }
    return parseFloat(disRate.toFixed(2));
  },
  isMobile: (num) => {
    var isphone =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
    return isphone;
  },
  coordinateLocal: async () => {
    let coordataL;
    function asignData(data) {
      coordataL = data;
    }
    await navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      let jsonCoords = {
        lat: lat,
        lng: lng,
      };
      jsonCoords = JSON.stringify(jsonCoords);
      asignData(jsonCoords);
      localStorage.setItem("geoLocal", jsonCoords);
      // console.log('coordataL', localStorage.getItem('geoLocal'))
      // reactLocalStorage.set('geoLocal', jsonCoords);
    });
    return coordataL;
  },
  addDay: (date, day) => {
    return date.setDate(new Date().getDate() + day)
  },

  minBookingDate: () => {
    let minDay = common.addDay(new Date(), 2);
    if(new Date().getHours() < 17){
      minDay = common.addDay(new Date(), 1);
    }
    return minDay;
  },
  
  creditCardType: (cardType) => {
    let imageUrl;
    cardType = cardType.toLowerCase();
    switch (cardType) {
      case "visa":
        imageUrl = "card-logo-visa.svg";
        break;
      case "mastercard":
        imageUrl = "card-logo-mastercard.svg";
        break;
      case "american-express":
        imageUrl = "card-logo-amex.svg";
        break;
      default:
        imageUrl = "card-logo-unknown.svg";
    }
    return imageUrl;
  },
  imageValidate: (extention, size, msg) => {
    extention = extention || ["image/jpg", "image/jpeg", "image/png"];
    msg = msg || "You need to attach image";
    size = size || 1024 * 1024;
    let image = Yup.mixed()
      .required(msg)
      .test("fileSize", "The image is too large", (value) => {
        if (value == undefined || value == null) {
          return false;
        }
        return value && value?.size <= size;
      })
      .test(
        "type",
        `Only the following formats are accepted: ${extention}`,
        (value) => {
          if (value == undefined || value == null) {
            return false;
          }
          let fileType = value.type;
          return value && extention.indexOf(fileType) != -1;
        }
      );
    return image;
  },
  uploadImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data._id;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },

  uploadImage2: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data._id;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },


  uploadWebPopImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.popupWebImage);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data._id;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },

  uploadMobilePopImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.popupMobileImage);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data._id;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },

  getDelete: async (id) => {
    let data;
    common.loader(true);
    data = await apiFunc
      .getDelete(id)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },
  uploadDropzoneFile: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await apiFunc
      .postUpload(formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
    return data;
  },
  error: (error) => {
    common.loader(false);
    // var message = JSON?.parse(error?.request?.response)?.message;
    var message = error?.response?.data?.message;
    toast.error(message);
    return message;
  },
  generateQOID: async () => {
    let resData = null;
    resData = await apiFunc
      .getOptionId()
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        common.error(error);
      });

    return resData;
  },
  bufferToBase64: (arr) => {
    //arr = new Uint8Array(arr) if it's an ArrayBuffer
    let buffer = btoa(
      arr.reduce((data, byte) => data + String.fromCharCode(byte), "")
    );
    return "data:image/png;base64," + buffer;
  },
  getImage: async (id) => {
    if (id) {
      let data;
      data = await apiFunc
        .getImage(id)
        .then((res) => {
          return common.bufferToBase64(res.data.data.Body.data);
        })
        .catch((error) => {
          common.error(error);
        });
      return data;
    }
  },
  aadharValidate: (num) => {
    let regexp = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
    return regexp.test(num);
  },
  isAlphabet: (event) => {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  },
  DateFormat: (date) => {
    return format(new Date(date), "LLLL dd, yyyy ");
    // output like
    // January 08, 2022
  },
  dateFormatWithTime: (date) => {
    return moment(date).format('DD-MM-YYYY, hh:mm A')
  },
  dateFormatWithoutTime: (date) => {
    return moment(date).format('DD-MM-YYYY')
  },
  datePickerFormat: () => {
    return "dd-MM-yyyy"
  },
  datePickerFormatWithTime: () => {
    return "dd-MM-yyyy , hh:mm a"
  },


  UploadCsv: (path) => {
    async function upload(e) {
      const csv = e?.target?.files[0];

      // const uploadedFile = new FormData();
      // uploadedFile.append("csv", new Blob([csv], { type: "text/csv" }), city);
      const formData = new FormData();
      formData.append("csv", csv);
      common.loader(true);
      await authAxios({
        method: "POST",
        url: `${ROOT_URL}/csv/upload/${path}`,
        data: formData,
      })
        .then((res) => {
          let data = res.data.data;
        })
        .catch((error) => {
          common.error(error);
        });
      common.loader(false);
    }

    return (
      <>
        <input
          type="file"
          id="files"
          onChange={(e) => upload(e)}
          className="form-control"
          style={{ display: "none" }}
          accept=".csv"
        />
        <label
          className="csvBtn ml-2 btn-custom  btn-theme  btn-sm "
          style={{ height: "33px", width: "auto" }}
          for="files"
        >
          {/* <i
            className="fa fa-upload"
            style={{ height: "5px", width: "5px", marginRight: "8px" }}
          ></i> */}
          Upload
        </label>
      </>
    );
  },
};

export default common;
