import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/theme";
import PageBreadCrumbs, { PageName } from "./headerBreadcrumbs";
import common from "../services/common";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { format } from "date-fns";

const Setting = [
  {
    title: "Settings",
    icon: "icon-settings",
    to: "/setting",
  },
  {
    title: "Change Password",
    icon: "icon-key",
    to: "/change-password",
  },
  {
    title: "Logout",
    icon: "icon-log-out",
    to: "/",
  },
];

function TopNavbar() {
  const { userInfo, MiniSidebar, setMiniSidebar, setAuth } =
    useContext(UserContext);
  const role = userInfo?.role;

  useEffect(()=>{
    if(role === 'BLOG-MANAGER' || role === "SUB-ADMIN-OPS" ){
      Setting.splice(0,1);
    }
  }, [role]);
  let navigate = useNavigate();

  useEffect(() => {
    getNotificationData();
  }, []);

  const [notification, setNotification] = useState([]);
  const getNotificationData = async () => {
    common.loader(true);
    await authAxios({
      method: "GET",
      url: `${ROOT_URL}/notifications/getlatestNotification`,
    })
      .then((res) => {
        let data = res.data.data;
        setNotification(data);
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
  };

  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };
  function ToggleSidebar() {
    setMiniSidebar(!MiniSidebar);
  }
  const Logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };

  const MarkNotificationAsRead = async (id) => {
    common.loader(true);
    await authAxios({
      method: "POST",
      url: `${ROOT_URL}/notifications/markAsRead/${id}`,
      data: {},
    })
      .then((res) => {
        let data = res.data.data;
        getNotificationData();
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
  };

  const MarkAllRead = async () => {
    common.loader(true);
    await authAxios({
      method: "POST",
      url: `${ROOT_URL}/notifications/markAllRead`,
      data: {},
    })
      .then((res) => {
        let data = res.data.data;
        getNotificationData();
      })
      .catch((error) => {
        common.error(error);
      });
    common.loader(false);
  };

  return (
    <nav className="navbar header-navbar">
      <div className="navbar-wrapper w-100">
        <div className="navbar-container container-fluid">
          <div className="row w-100 align-items-center">
            <div className="col-6">
              <div className="nav-left mr-auto">
                <div className="menu-hamburger ml-1 fs-5">
                  <span
                    className="icon-menu"
                    // onClick={() => ToggleSidebar()}
                  ></span>
                  <span
                    className="icon-x"
                    // onClick={() => ToggleSidebar()}
                  ></span>
                  <span className="bredcrumHead">
                    <PageName />
                  </span>
                </div>
                <div className="slideUp_header  ml-1 fs-5">
                  <span className="icon-arrow-up"></span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <ul className="nav-right d-flex align-items-center justify-content-end mb-0">
                {role && role !== 'BLOG-MANAGER' && <li className="header-notification pr-5">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="dropdown-toggle position-relative"
                      onClick={ToggleClass}
                      type="button"
                      id="notificaitonBell"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="feather icon-bell"></i>
                      <span className="badge bg-danger rounded-circle position-absolute">
                        {notification?.length}
                      </span>
                    </div>
                    <ul
                      className={
                        isActive
                          ? "dropdown-menu dropdown-menu-end"
                          : "dropdown-menu dropdown-menu-end show"
                      }
                      aria-labelledby="notificaitonBell"
                    >
                      <li className="p-3 d-flex align-items-center justify-content-between">
                        <h6>Notifications</h6>
                        <div>
                          <label
                            className={`badge fw-normal ${
                              notification?.length == 0
                                ? "bg-success"
                                : "bg-warning"
                            } rounded mx-4`}
                            onClick={() => MarkAllRead()}
                          >
                            {notification?.length == 0 ? "" : " Mark Read All"}
                          </label>
                          {/* <label className="badge fw-normal bg-warning rounded">
                            New
                          </label> */}
                        </div>
                      </li>
                      {notification?.map((item, i) => (
                        <li
                          className="px-3  "
                          key={i}
                          // onClick={() => {
                          //   item.productId && navigate("/sp-list/1");
                          //   if (item.title === "SP-VERIFICATION")
                          //     navigate(`/serviceProvider/1`);
                          // }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="media d-flex align-items-center">
                            <div className="media-body flex-shrink-1 pl-1">
                              <h5 className="notification-user">
                                {item.title}
                              </h5>

                              <p className="notification-msg">
                                {item.description}
                              </p>
                            </div>
                            <div className="notification_right">
                              <span className="notification-time ml-2">
                                {format(
                                  new Date(item.createdAt),
                                  " dd LLLL, yyyy "
                                )}
                              </span>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    MarkNotificationAsRead(item._id);
                                  }}
                                  className="btn btn-primary btn-sm mt-2 ml-2"
                                >
                                  Mark read
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>}

                {/* User profile  */}
                <li className="user-profile header-notification">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {userInfo?.userImage?.path ? (
                        <img
                          src={userInfo?.userImage?.path}
                          className="img-radius img-fluid "
                          style={{ height: "35px" }}
                          alt="User-Profile"
                        />
                      ) : (
                        <img
                          src={`/assets/images/avatar-3.jpg`}
                          className="img-radius img-fluid"
                          alt="User-Profile"
                        />
                      )}
                      <span>{userInfo.name}</span>
                      <i className="feather icon-chevron-down"></i>
                    </div>
                    <ul
                      className="show-notification dropdown-menu-end profile-notification dropdown-menu"
                      data-dropdown-in="fadeIn"
                      data-dropdown-out="fadeOut"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {Setting.map((item, i) =>
                        item.title == "Logout" ? (
                          <li key={i}>
                            <a onClick={() => Logout()}>
                              <i className={item.icon}></i> {item.title}
                            </a>
                          </li>
                        ) : (
                          <li key={i}>
                            <Link to={item.to}>
                              <i className={item.icon}></i> {item.title}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopNavbar;
