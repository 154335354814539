import React from "react";

const LeftWidget = () => {
  return (
    <div className="log_LeftWidget_wrap">
      <div className="logoContain">
        <img
          src={`/assets/images/samskaraLogo.png`}
          alt="Logo"
          className="side__logo"
        />
      </div>
      <div className="secondaryImg">
        <img src="/assets/images/ganesh.png" alt="dashboard" />
      </div>
      <div className="content_wrap">
        <h2>Lorem ipsum dolor sit</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      </div>
    </div>
  );
};

export default LeftWidget;
